<!--
  @component
  
  Takes care setting up stores on client-side.
-->
<script lang="ts">  
  import { onMount } from 'svelte';
  import { StoresContext } from '~/stores';
  import type { StoresContextData } from '~/stores';
  import type { StoresKey } from "~/stores";
  import { DEV } from 'esm-env';

  export let request_store_key: StoresKey;
  export let stores_context: StoresContextData;
  
  onMount(() => {
    try {
      StoresContext.from(stores_context, request_store_key);
      // @ts-ignore
      if (DEV) window._page_data_ = stores_context;
    } catch (error) {
      console.error(error);
    }
  });
  
</script>

